import { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { getOrganization } from '../../data/api/organization'
import { apiToUserDto, getMe } from '../../data/api/users'
import { useAuth } from '../../hooks/auth-context'
import { useOrganization } from '../../hooks/organization-context'
import { isOperator } from '../../lib/auth/authService'
import auth from '../../lib/auth/localStorage'
import { isApiResultSuccess } from '../../lib/axios'
import history from '../../lib/history'
import { AuthRoutes } from '../../lib/route-builder'

const OperatorRoute = ({ ...props }) => {
	const { setUser, user } = useAuth()
	const { setOrganization, organization } = useOrganization()

	useEffect(() => {
		if (auth.getUserId()) {
			getMe().then((result) => {
				if (isApiResultSuccess(result)) {
					if (isOperator()) {
						setUser(apiToUserDto(result.data))
					} else {
						history.push(AuthRoutes.login())
					}
				}
			})
		} else {
			history.push(AuthRoutes.login())
		}
		if (auth.getOrganizationId()) {
			getOrganization(auth.getOrganizationId()!).then((result) => {
				if (isApiResultSuccess(result)) {
					setOrganization(result.data)
				} else {
					history.push(AuthRoutes.login())
				}
			})
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	if (user && organization) {
		return <Route {...props} />
	}

	return <div />
}

export default OperatorRoute
