import { ApiUserRole } from '../../data/api/users'

const ACCESS_TOKEN = 'GZ_ACCESS_TOKEN'
const REFRESH_TOKEN = 'GZ_REFRESH_TOKEN'
const AUTH_USER = 'GZ_AUTH_USER'
const AUTH_ORGANIZATION = 'GZ_AUTH_ORGANIZATION'
const AUTH_USER_ROLE = 'GZ_USER_ROLE'

class LocalStorage {
	storeAccessToken(token: string) {
		localStorage.setItem(ACCESS_TOKEN, token)
	}
	storeRefreshToken(token: string) {
		localStorage.setItem(REFRESH_TOKEN, token)
	}
	storeUserId(userId: string) {
		localStorage.setItem(AUTH_USER, userId)
	}
	storeUserRole(role: ApiUserRole) {
		localStorage.setItem(AUTH_USER_ROLE, role)
	}
	storeOrganizationId(organizationId: string) {
		localStorage.setItem(AUTH_ORGANIZATION, organizationId)
	}
	getAccessToken() {
		return localStorage.getItem(ACCESS_TOKEN)
	}
	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN)
	}
	getUserId() {
		return localStorage.getItem(AUTH_USER)
	}
	getOrganizationId() {
		return localStorage.getItem(AUTH_ORGANIZATION)
	}
	getUserRole() {
		return localStorage.getItem(AUTH_USER_ROLE)
	}
	clearOrganization() {
		localStorage.removeItem(AUTH_ORGANIZATION)
	}
	clear() {
		localStorage.removeItem(ACCESS_TOKEN)
		localStorage.removeItem(REFRESH_TOKEN)
		localStorage.removeItem(AUTH_USER)
		localStorage.removeItem(AUTH_ORGANIZATION)
		localStorage.removeItem(AUTH_USER_ROLE)
	}
}

const auth = new LocalStorage()
export default auth
