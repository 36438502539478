export const AuthRoutes = {
	root: () => '/auth',
	login: () => '/auth/login',
	resetPassword: ({ token, userId }: { token: string; userId: string }) => `/auth/reset-password?token=${token}&userId=${userId}`,
	forgotPassword: () => '/auth/forgot-password',
	emailSent: () => '/auth/forgot-password-email-sent',
	invitation: ({ token, userId, expiresAt }: { token: string; userId: string; expiresAt: string }) => `/auth/invitation?token=${token}&userId=${userId}&expiresAt=${expiresAt}`,
}

export const AdminRoutes = {
	root: () => '/admin',

	organizationList: () => '/admin/organizations',

	siteList: (organizationId: string) => `/admin/${organizationId}/sites`,

	site: ({ organizationId, siteId }: { organizationId: string; siteId: string }) => `/admin/${organizationId}/sites/${siteId}`,

	addSite: (organizationId: string) => `/admin/${organizationId}/sites/add`,

	cluster: ({ clusterId, siteId, organizationId }: { clusterId: string; siteId: string; organizationId: string }) => `/admin/${organizationId}/sites/${siteId}/clusters/${clusterId}`,

	addCluster: (organizationId: string, siteId: string) => `/admin/${organizationId}/sites/${siteId}/clusters/add`,

	station: ({ stationId, clusterId, siteId, organizationId }: { stationId: string; clusterId: string; siteId: string; organizationId: string }) =>
		`/admin/station?organizationId=${organizationId}&siteId=${siteId}&clusterId=${clusterId}&stationId=${stationId}`,

	addStation: (organizationId: string, siteId: string, clusterId: string) => `/admin/${organizationId}/sites/${siteId}/clusters/${clusterId}/addStation`,

	vehicle: ({ organizationId, vehicleId }: { organizationId: string; vehicleId: string }) => `/admin/${organizationId}/vehicles/${vehicleId}`,

	vehicleList: (organizationId: string) => `/admin/${organizationId}/vehicles`,

	addVehicle: (organizationId: string) => `/admin/${organizationId}/vehicles/add`,

	userList: () => '/admin/users',

	userProfile: (userId: string) => `/admin/users/${userId}`,

	myProfile: () => '/admin/profile',

	userAdd: () => '/admin/users/add',

	operatorList: (organizationId: string) => `/admin/${organizationId}/operators`,

	operator: ({ organizationId, operatorId }: { organizationId: string; operatorId: string }) => `/admin/${organizationId}/operators/${operatorId}`,

	addOperator: (organizationId: string) => `/admin/${organizationId}/operators/add`,

	routeList: (organizationId: string) => `/admin/${organizationId}/routes`,

	route: ({ organizationId, routeId }: { organizationId: string; routeId: string }) => `/admin/${organizationId}/routes/${routeId}`,

	routeAdd: (organizationId: string) => `/admin/${organizationId}/routes/add`,
}

export const OperatorRoutes = {
	calendarList: () => '/operator/calendar',
	calendar: (vehicleId: string) => `/operator/calendar/${vehicleId}`,

	myProfile: () => '/operator/profile',

	stationList: () => '/operator/stations',
	station: (stationId: string) => `/operator/stations/${stationId}`,

	routeList: (organizationId: string) => `/operator/${organizationId}/routes`,

	route: ({ organizationId, routeId }: { organizationId: string; routeId: string }) => `/operator/${organizationId}/routes/${routeId}`,

	routeAdd: (organizationId: string) => `/operator/${organizationId}/routes/add`,
}
