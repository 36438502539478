import { axiosApi } from '../../lib/axios'
import { Language } from '../../utils/language'
import auth from '../../lib/auth/localStorage'

export interface User {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	language: Language;
	timezone: string;
	status: ApiUserStatus;
	role: ApiUserRole;
	organizationId?: string;
}

export interface ApiCreateUserDto {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	language: Language;
	timezone: string;
	organizationId?: string;
	isOrganizationOwner?: boolean;
	organizationIds?: string[];
}

export interface ApiUpdateUserDto {
	firstName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	language: Language;
	timezone: string;
}

export interface ApiLoginDto {
	email: string;
	password: string;
}

export interface ApiTokensDto {
	accessToken: string;
	refreshToken: string;
}

export interface ApiResetPasswordEmailDto {
	email: string;
}

export interface ApiResetPasswordDto {
	token: string;
	userId: string;
	newPassword: string;
}

export interface ApiChangePasswordDto {
	oldPassword: string;
	newPassword: string;
}

export interface ApiUserDto {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	emailVerified: boolean;
	emailVerificationToken: {
		token: string;
		expireAt: string;
	} | null;
	phoneNumber: string;
	language: Language;
	timezone: string;
	status: ApiUserStatus;
	role: ApiUserRole;
	organizationId?: string;
	passwordResetToken: {
		token: string;
		expireAt: string;
	} | null;
	createdAt: string;
	createdBy: string | null;
	updatedAt: string | null;
	updatedBy: string | null;
	deletedAt: string | null;
	deletedBy: string | null;
}

export enum ApiUserStatus {
	Active = 'active',
	Inactive = 'inactive',
	Invited = 'invited',
}

export enum ApiUserRole {
	SuperAdmin = 'super-admin',
	Admin = 'admin',
	Operator = 'operator',
}

export const apiToUserDto = (apiUserDto: ApiUserDto): User => {
	return {
		id: apiUserDto.id,
		firstName: apiUserDto.firstName,
		lastName: apiUserDto.lastName,
		email: apiUserDto.email,
		phoneNumber: apiUserDto.phoneNumber,
		language: apiUserDto.language,
		timezone: apiUserDto.timezone,
		status: apiUserDto.status,
		role: apiUserDto.role,
		organizationId: apiUserDto.organizationId,
	}
}

export function login(loginDto: ApiLoginDto) {
	return axiosApi<ApiTokensDto>('/v1/auth/login', 'POST', {
		body: JSON.stringify(loginDto),
	})
}

export function refreshToken() {
	return axiosApi<ApiTokensDto>('/v1/auth/refresh', 'POST', {
		body: JSON.stringify({ refreshToken: auth.getRefreshToken() }),
	})
}

export function createSuperAdmin(createUserDto: ApiCreateUserDto) {
	return axiosApi<ApiUserDto>('/v1/users/super-admins', 'POST', {
		body: JSON.stringify(createUserDto),
	})
}

export function createOperator(createUserDto: ApiCreateUserDto) {
	return axiosApi<ApiUserDto>('/v1/users/operators', 'POST', {
		body: JSON.stringify(createUserDto),
	})
}

export function updateUser(updateUserDto: ApiCreateUserDto, userId: string) {
	return axiosApi<ApiUserDto>(`/v1/users/${userId}`, 'PUT', {
		body: JSON.stringify(updateUserDto),
	})
}

export function deleteUser(userId: string) {
	return axiosApi(`/v1/users/${userId}`, 'DELETE')
}

export function activateUser(userId: string) {
	return axiosApi<ApiUserDto>(`/v1/users/${userId}/activate`, 'POST')
}

export function deactivateUser(userId: string) {
	return axiosApi<ApiUserDto>(`/v1/users/${userId}/deactivate`, 'POST')
}

export function forgotPassword(resetPasswordEmailDto: ApiResetPasswordEmailDto) {
	return axiosApi('/v1/users/password/reset/send/', 'POST', {
		emailQuery: resetPasswordEmailDto.email,
	})
}

export function resetPassword(userId: string) {
	return axiosApi<string>(`/v1/users/${userId}/password/reset`, 'POST')
}

export function setPassword(resetPasswordDto: ApiResetPasswordDto) {
	return axiosApi<string>('/v1/users/password/set', 'POST', {
		body: JSON.stringify(resetPasswordDto),
	})
}

export function changePassword(changePasswordDto: ApiChangePasswordDto) {
	return axiosApi<string>('/v1/users/me/password/change', 'POST', {
		body: JSON.stringify(changePasswordDto),
	})
}

export function getMe() {
	return axiosApi<ApiUserDto>('/v1/users/me')
}

export function acceptTerms(userId: string) {
	return axiosApi<string>(`/v1/users/${userId}/accept-terms`, 'POST')
}

export const UserEndpoints = {
	me: () => '/v1/users/me',
}
