import { axiosApi } from '../../lib/axios'

export interface ApiOrganizationDto {
	id: string;
	name: string;
	defaultLanguage: string;
	defaultTimezone: string;
}

export function getOrganization(organizationId: string) {
	return axiosApi<ApiOrganizationDto>(`/v1/organizations/${organizationId}`)
}
