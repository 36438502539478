import axios, { Method } from 'axios'
import auth from '../auth/localStorage'

let _apiUrl: string

const apiUrl = (): string => {
	if (!_apiUrl) {
		const host = window.location.host

		if (host.indexOf('localhost') > -1 || host.indexOf('preview') > -1 || host.indexOf('192.168.68.136') > -1 || host.indexOf('bs-local') > -1) {
			_apiUrl = 'https://api.qa.gizmos.cloud'
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const [_, ...rest] = host.split('.')
			const apiHost = ['api', ...rest].join('.')
			_apiUrl = `https://${apiHost}`
		}
	}

	return _apiUrl
}

export interface ApiOptions {
	emailQuery?: string;
	headers?: Record<string, string>;
	body?: string;
}

export interface ApiErrorData {
	status: number;
	type: string;
	code: string;
	message: string;
	param?: string;
}

export type ApiResultSuccess<R> = {
	data: R;
	status: number;
};
export type ApiResultFailure = {
	data: ApiErrorData;
	status: number;
};
export type ApiResult<R> = ApiResultSuccess<R> | ApiResultFailure;

export function isApiResultSuccess<R>(apiResult: ApiResult<R>): apiResult is ApiResultSuccess<R> {
	return apiResult.status >= 200 && apiResult.status < 300
}

export async function axiosApi<R>(path: string, method: Method = 'GET', opts: ApiOptions = {}): Promise<ApiResult<R>> {
	const headers = Object.assign({}, opts.headers || {}, {
		'Content-type': 'application/json; charset=UTF-8',
	})

	let url = apiUrl() + path

	const emailQuery = opts.emailQuery
	if (emailQuery) {
		url = url + '?' + new URLSearchParams({ email: emailQuery })
	}

	const accessToken = auth.getAccessToken()

	if (accessToken) {
		headers['Authorization'] = 'Bearer ' + accessToken
	}

	headers['Access-Control-Allow-Origin'] = '*'
	return axios({
		method,
		url,
		headers,
		data: opts.body,
	})
		.then((result) => {
			return {
				data: result.data,
				status: result.status,
			}
		})
		.catch(function (error) {
			if (error.response) {
				return {
					data: error.response.data,
					status: error.response.status,
				}
			}
			return error
		})
}

export async function jsonFetcher<R>(path: string) {
	const result = await axiosApi<R>(path, 'GET')

	if (!isApiResultSuccess(result)) {
		throw result.data
	}

	return result.data
}
