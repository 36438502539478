import { Toaster } from 'react-hot-toast'

const ToasterDefault = () => {
	return (
		<Toaster
			position="bottom-center"
			toastOptions={{
				success: {
					duration: 5000,
					style: {
						borderRadius: '10px',
						background: '#00FF7F',
						textAlign: 'center'
					}
				},
				error: {
					duration: 5000,
					style: {
						borderRadius: '10px',
						background: '#FAA0A0',
						textAlign: 'center'
					}
				}
			}}
		/>
	)
}

export default ToasterDefault
