import React, { useContext, useState } from 'react'
import { User } from '../data/api/users'

const AuthContext = React.createContext<AuthState>({} as AuthState)

interface AuthState {
	user?: User;
	setUser: (user: User) => void;
	clearUser: () => void;
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useState<User>()
	const context = {
		user,
		setUser,
		clearUser: () => setUser(undefined),
	}

	return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
