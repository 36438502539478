import { ApiUserRole, refreshToken } from '../../data/api/users'
import { isApiResultSuccess } from '../axios'
import auth from './localStorage'

const HALF_HOUR = 1000 * 60 * 30

const _refreshToken = () => {
	if (auth.getRefreshToken()) {
		refreshToken().then((result) => {
			if (isApiResultSuccess(result)) {
				auth.storeAccessToken(result.data.accessToken)
				auth.storeRefreshToken(result.data.refreshToken)
			}
		})
	}
}

export const periodicRefresh = () => {
	_refreshToken()
	setInterval(_refreshToken, HALF_HOUR)
}

const userRoles = {
	admins: [String(ApiUserRole.SuperAdmin), String(ApiUserRole.Admin)],
	operators: [String(ApiUserRole.Operator)],
	all: [String(ApiUserRole.SuperAdmin), String(ApiUserRole.Admin), String(ApiUserRole.Operator)],
}

export const isAdmin = () => {
	const currentRole = auth.getUserRole()
	return currentRole ? userRoles.admins.includes(currentRole) : false
}

export const isOperator = () => {
	const currentRole = auth.getUserRole()
	return currentRole ? userRoles.operators.includes(currentRole) : false
}
